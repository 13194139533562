import { client } from './modules/client';
import { contacts } from './modules/contacts';
import { employees } from './modules/employees';
import { hourBudget } from './modules/hour-budget';
import { user } from './modules/user';
import { inspection } from './modules/inspection';
import { sms } from './modules/sms';
import { weeklyRevision } from './modules/weekly-revision';
import { store as toastStore, namespace as toastNamespace } from './modules/toast';
import { store as infotechStore, namespace as infotechNamespace } from '../views/integrations/store/infotech-store';
import { store as tripletexStore, namespace as tripletexNamespace } from '../views/integrations/store/tripletex-store';
import { store as hourStampStore, namespace as hourStampNamespace } from '../views/hours/store/hour-stamp';
import { store as cordelStore, namespace as cordelNamespace } from '../views/integrations/store/cordel-store';
import { store as boligmappaStore, namespace as boligmappaNamespace } from '../views/integrations/store/boligmappa-store';
import { store as cvStore, namespace as cvNamespace } from '../views/hr/stores/cv-store';
import { store as emailLogStore, namespace as emailLogNamespace } from '../views/email-log/stores/email-log-store';
import { store as departmentStore, namespace as departmentNamespace } from '../views/employee/stores/department-store';
import { vuexLoggingPlugin } from '../libs/vuex-logging-plugin';

let vuexPlugins = [];

if (import.meta.env.VITE_NODE_ENV !== 'production') {
    vuexPlugins.push(vuexLoggingPlugin);
}

export const root = {
    plugins: vuexPlugins,
    state: {},
    modules: {
        client,
        contacts,
        employees,
        hourBudget,
        user,
        inspection,
        sms,
        weeklyRevision,
        [toastNamespace]: toastStore,
        [infotechNamespace]: infotechStore,
        [hourStampNamespace]: hourStampStore,
        [tripletexNamespace]: tripletexStore,
        [cordelNamespace]: cordelStore,
        [boligmappaNamespace]: boligmappaStore,
        [cvNamespace]: cvStore,
        [emailLogNamespace]: emailLogStore,
        [departmentNamespace]: departmentStore,
    },
    strict: import.meta.env.VITE_NODE_ENV !== 'production',
};
